import { atom } from "recoil";
export const companyPlan = atom<string | undefined>({
  key: "companyPlan",
  default: 'free',
});

export const isTrial = atom<string | undefined>({
  key: "isTrial",
  default: 'undefined',
});

export const accountFeatures = atom<any | undefined>({
  key: "accountFeatures",
  default: undefined,
});

export const showContactUs = atom<boolean>({
  key: "showContactUs",
  default: false,
});