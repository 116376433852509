import React from "react";
import { LayoutProps } from ".";
import Menu from "./menu";
import authService from "../auth.service";

type State = {
  isAuthenticated: boolean;
};

export default class Header extends React.Component<LayoutProps, State> {
  redirectPage() {
    authService.redirectRole(authService.getUserRole(), authService.getProviderId());
  }
  constructor(props: LayoutProps) {
    super(props);
    this.state = {
      isAuthenticated: false,
    };
  }
  async checkAuthenticated() {
    const isAuthenticated = await authService.isAuthenticated();
    this.setState({
      isAuthenticated: isAuthenticated,
    });
  }

  async componentDidMount() {
    this.checkAuthenticated();
  }
  render() {
    return (
      <>
        {this.state.isAuthenticated && (
          <>
            <div className="navbar-logo">
              <a href="#" onClick={this.redirectPage}>
                <img src={process.env.PUBLIC_URL + "/harmonic-logo-full-colour.png"} width="120" alt="logo" />
              </a>
            </div>
            <Menu {...this.props}></Menu>
          </>
        )}
      </>
    );
  }
}
