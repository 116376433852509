import * as React from "react";
import Helper from "../utils/helper";
import _ from "lodash";

type Props = {
  name: any;
  profile_color: string;
  width?: number;
  height?: number;
  className?: string
  fontSize?: number;
  onClick?: Function;
};
const AvatarText = (props: Props) => {
  const avatarWidth = props.width ? props.width : 70;
  const avatarHeight = props.height ? props.height : 70;
  const fontSize = props.fontSize ? props.fontSize : 'auto';
  return (
    <span
      onClick={() => props.onClick ? props.onClick() : _.noop()}
      className={`people-first-character text-white ${props.className}`}
      style={{
        width: avatarWidth,
        height: avatarHeight,
        maxWidth: avatarWidth,
        backgroundColor: `#${props.profile_color}`,
        position: "unset",
        fontSize: fontSize
      }}>
      {props.name ? Helper.getFirstCharacter(props.name) : ""}
    </span>
  );
};

export default AvatarText;
