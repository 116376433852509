import React, { useEffect, useState } from "react";
import Body from "../layout/body";
import Header from "../layout/header";
import { RouteComponentProps, withRouter } from "react-router";
import { Profile } from "../definitions";
import Sidebar from "./sidebar";
import AuthService from "./../auth.service";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { affinityTeamId, userProfile } from "../recoil/userProfile";
import { showContactUs } from "../recoil/company";
import ContactUs from "../components/pricing/contact";

export type LayoutProps = RouteComponentProps & {
  currentUser?: Profile;
  isLoggedIn?: boolean;
  children?: any;
};
const PrivateLayout = (props: LayoutProps) => {
  const checkShowMenuLeft = _.find(["/workstyle-time", "/organization"], (path) => {
    return path === window.location.pathname;
  })
    ? true
    : false;

  const checkFullWidh = _.find(["/workstyle-time"], (path) => {
    return path === window.location.pathname;
  })
    ? true
    : false;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showMenuLeft, setShowMenuLeft] = useState(checkShowMenuLeft);
  const [currentUserProfile, setCurrentUserProfile] = useRecoilState(userProfile);
  const [currentAffinityTeamId] = useRecoilState(affinityTeamId);
  const [showCurrentContactUs, setShowCurrentContactUs] = useRecoilState(showContactUs);
  useEffect(() => {
    getAuthenticated();
    if (!currentAffinityTeamId) {
      // props.history.push("/organization");
    }
  }, []);
  useEffect(() => {
    if (props.currentUser) {
      setCurrentUserProfile(props.currentUser);
    }
  }, [props.currentUser]);
  const getAuthenticated = async () => {
    let isAuthenticated = await AuthService.isAuthenticated();
    setIsAuthenticated(isAuthenticated);
    // if (isAuthenticated) {
    //   setShowMenuLeft(false);
    // }
  };
  return (
    <React.Fragment>
      {isAuthenticated && (
        <aside className={`sidebar-nav-wrapper ${!showMenuLeft ? "" : "active"}`}>
          <Header {...props}></Header>
        </aside>
      )}
      {isAuthenticated && (
        <div
          onClick={() => setShowMenuLeft(!showMenuLeft)}
          className={`${!showMenuLeft ? "" : "active overlay"}`}></div>
      )}
      <main
        className={`main-wrapper ${!showMenuLeft ? "" : "active"} ${window.location.pathname === "/" ? "ml-0" : ""} ${
          checkFullWidh ? "pb-0" : ""
        }`}>
        <Sidebar
          currentUser={props.currentUser}
          icon={!showMenuLeft ? "lni-menu" : "lni-chevron-left"}
          onChangeShowMenu={(e: any) => {
            setShowMenuLeft(!showMenuLeft);
          }}
        />
        <section className="section">
          <div className={!checkFullWidh ? "container-fluid" : ""}>
            <Body {...props}>
              {React.Children.map(props.children, (child) => {
                return React.cloneElement(child as any, { ...props });
              })}
            </Body>
          </div>
        </section>
      </main>
      {showCurrentContactUs && <ContactUs onChange={() => setShowCurrentContactUs(false)} />}
    </React.Fragment>
  );
};

export default withRouter(PrivateLayout);
