import axios from "axios";
import AuthService from "./auth.service";
import {
  AdminSettings,
  Dimension,
  DimensionCategory,
  Interest,
  Lesens,
  Profile,
  ProfileConnection,
  Question,
  Settings,
  Team,
} from "./definitions";

class axiosClient {
  token: string | undefined;
  teamId: string | undefined;
  HTTP: any;
  HTTPFILE: any;

  constructor(token?: string, teamId?: string) {
    this.token = token ? token : AuthService.getToken();
    this.teamId = teamId ? teamId : AuthService.getTeamId();
    this.HTTP = axios.create({
      baseURL: process.env.REACT_APP_API_URL || "HTTP://127.0.0.1:5000/api",
      headers: {
        Authorization: `Token token=${token}`,
        companyProviderId: this.teamId,
      },
    });

    this.HTTPFILE = axios.create({
      baseURL: process.env.REACT_APP_API_URL || "HTTP://127.0.0.1:5000/api",
      headers: {
        Authorization: `Token token=${token}`,
        companyProviderId: this.teamId,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  resetToken(token: string, teamId?: string) {
    this.token = token;

    if (teamId) {
      this.teamId = teamId;
    }

    this.HTTP.defaults.headers = {
      Authorization: `Token token=${this.token}`,
      companyProviderId: this.teamId,
    };
  }

  async saveToken(code: any): Promise<Profile> {
    const result = await this.HTTP.post("/auth/token", { code: code });
    return result.data;
  }

  async deleteToken(): Promise<any> {
    const result = await this.HTTP.post("/auth/logout");
    return result.data;
  }

  async getProfiles(data?: any): Promise<Profile[]> {
    const params: any = {};
    if (data?.name) {
      params.name = data.name;
    }
    if (data?.team) {
      params.team = data.team;
    }
    if (data?.page) {
      params.page = data.page;
    }
    const result = await this.HTTP.get(`/profile`, { params: params });
    return result.data;
  }

  async getPeople(data?: any): Promise<Profile[]> {
    const params: any = {};
    if (data?.name) {
      params.name = data.name;
    }
    if (data?.team) {
      params.team = data.team;
    }
    if (data?.page) {
      params.page = data.page;
    }
    const result = await this.HTTP.get(`/people`, { params: params });
    return result.data;
  }

  async getProfile(providerId: string): Promise<Profile> {
    const result = await this.HTTP.get(`/profile/${providerId}`);
    return result.data;
  }

  async getProfileConnection(providerId: string): Promise<ProfileConnection> {
    const result = await this.HTTP.get(`/profile/${providerId}/connection`);
    return result.data;
  }

  async getCurrentProfile(): Promise<Profile> {
    const result = await this.HTTP.get("/profile/current");
    return result.data;
  }

  async getCurrentProfileInfo(token?: string): Promise<any> {
    const headers = { Authorization: `Bearer ${token}` };
    const result = await this.HTTP.post("/profile/current_info", {}, { headers });
    return result.data;
  }

  async saveProfile(profile: Profile): Promise<Profile> {
    const result = await this.HTTP.post("/profile", { profile });
    return result.data;
  }

  async removeProfile(providerId: string): Promise<void> {
    const result = await this.HTTP.post("/profile/remove", { provider_id: providerId });
    return result.data;
  }

  async saveProfileAdminStatus(profile: Profile, makeAdmin: boolean): Promise<Profile> {
    const result = await this.HTTP.post("/profile/admin_status", { user_id: profile.id, is_admin: makeAdmin });
    return result.data;
  }

  async getTeams(): Promise<Team[]> {
    const result = await this.HTTP.get("/teams");
    return result.data;
  }

  async getProfileNotBelongToTeam(teamId: number): Promise<Team[]> {
    const result = await this.HTTP.get("/teams/get_use_not_be_long_to_team?team_id=" + teamId);
    return result.data;
  }

  async saveTeam(team: Team): Promise<Team> {
    const result = await this.HTTP.post("/teams", { team });
    return result.data;
  }

  async updateTeam(team: Team): Promise<Team> {
    const result = await this.HTTP.put("/teams", { team });
    return result.data;
  }

  async deleteTeam(team: Team): Promise<Team> {
    const result = await this.HTTP.delete(`/teams?team_id=${team.id}`);
    return result.data;
  }

  async saveProfileToTeam(profile: Profile, team: Team): Promise<void> {
    const result = await this.HTTP.post("/teams/add", { user_provider_id: profile.provider_id, team_id: team.id });
    return result.data;
  }

  async saveProfilesToTeam(provider_ids: any[], team: Team): Promise<void> {
    const result = await this.HTTP.post("/teams/adds", { user_provider_ids: provider_ids, team_id: team.id });
    return result.data;
  }

  async removeProfileFromTeam(profile: Profile, team: Team): Promise<void> {
    const result = await this.HTTP.delete(`/teams/remove?user_provider_id=${profile.provider_id}&team_id=${team.id}`);
    return result.data;
  }

  async searchForMedia(searchTerm: string, media?: string) {
    const result = await axios.get(
      `https://itunes.apple.com/search?term=${encodeURIComponent(searchTerm)}&media=${media}`
    );
    return result.data;
  }

  async getInterests() {
    const result = await this.HTTP.get("/profile/interest");
    return result.data;
  }

  async addInterests(interest: Interest) {
    const result = await this.HTTP.post("/profile/interest", { interest });
    return result.data;
  }

  async removeInterests(interestId: number) {
    const result = await this.HTTP.delete(`/profile/interest?id=${interestId}`);
    return result.data;
  }

  async getAllInterests() {
    const result = await this.HTTP.get("/interests");
    return result.data;
  }

  async getSettings(): Promise<Settings> {
    const result = await this.HTTP.get("/settings");
    return result.data;
  }

  async getSettingQuestionnaire(): Promise<Settings> {
    const result = await this.HTTP.get("/settings/getsetting");
    return result.data;
  }

  async saveSettings(settings: Settings): Promise<Settings> {
    const result = await this.HTTP.post("/settings", { settings });
    return result.data;
  }

  //admin questions
  async getAllQuestions(): Promise<Question[]> {
    const result = await this.HTTP.get("/admin/questions");
    return result.data;
  }

  async updateQuestion(question: Question): Promise<Question> {
    const result = await this.HTTP.put("/admin/questions/" + question.id, { question });
    return result.data;
  }

  async createQuestion(question: Question): Promise<Question> {
    const result = await this.HTTP.post("/admin/questions", { question });
    return result.data;
  }

  //admin dimension categories
  async getAllDimensionCategories(): Promise<DimensionCategory[]> {
    const result = await this.HTTP.get("/admin/question_dimension_categories");
    return result.data;
  }

  async updateDimensionCategory(dimensionCategory: DimensionCategory): Promise<DimensionCategory[]> {
    const result = await this.HTTP.put("/admin/question_dimension_categories/" + dimensionCategory.id, {
      question_dimension_category: dimensionCategory,
    });
    return result.data;
  }

  async createDimensionCategory(dimensionCategory: DimensionCategory): Promise<DimensionCategory[]> {
    const result = await this.HTTP.post("/admin/question_dimension_categories", {
      question_dimension_category: dimensionCategory,
    });
    return result.data;
  }

  //admin dimensions
  async getAllDimensions(): Promise<Dimension[]> {
    const result = await this.HTTP.get("/admin/question_dimensions");
    return result.data;
  }

  async updateDimension(dimension: Dimension): Promise<Dimension[]> {
    const result = await this.HTTP.put("/admin/question_dimensions/" + dimension.id, { question_dimension: dimension });
    return result.data;
  }

  async createDimension(dimension: Dimension): Promise<Dimension[]> {
    const result = await this.HTTP.post("/admin/question_dimensions", { question_dimension: dimension });
    return result.data;
  }

  //admin settings
  async getAdminSettings(): Promise<AdminSettings> {
    const result = await this.HTTP.get("/admin/settings");
    return result.data;
  }

  async saveAdminSettings(settings: AdminSettings): Promise<AdminSettings[]> {
    const result = await this.HTTP.post("/admin/settings", { settings });
    return result.data;
  }

  async getLession(lesens: Lesens): Promise<any> {
    const result = await this.HTTP.get(
      `/connection?from_id=${lesens.from_id}&from_type=${lesens.from_type}&to_id=${lesens.to_id}&to_type=${lesens.to_type}`
    );
    return result.data;
  }

  // Company
  async getCompanies(): Promise<any[]> {
    const result = await this.HTTP.get("/company");
    return result.data;
  }

  async getInterestCategories(): Promise<any[]> {
    const result = await this.HTTP.get("/interests/getInterestCategories");
    return result.data;
  }

  async getAffinityAnswers(): Promise<any[]> {
    const result = await this.HTTP.get("/affinity_questions/get_affinity_answers");
    return result.data;
  }
  // postAffinityAnswers(data: any):Promise<any[]>{
  //     return this.HTTP.post('/affinity_questions/submit_affinity_answer', data).then((result: any) => result.data)
  // }

  async postAffinityAnswers(data: any): Promise<any[]> {
    const result = await this.HTTP.post("/affinity_questions/submit_affinity_answers", data);
    return result.data;
  }

  async getExternalAffinityQuestions(): Promise<any[]> {
    const result = await this.HTTP.get("/external/get_affinity_questions");
    return result.data;
  }
  async postExternalAffinityAnswers(data: any): Promise<any[]> {
    const result = await this.HTTP.post("/external/submit_answer", data);
    return result.data;
  }
  async getWorkstyle(id: String, is_personal?: boolean): Promise<any[]> {
    const result = await this.HTTP.get(`/external/profile?providerId=${id}&is_personal=${is_personal}`);
    return result.data;
  }

  async getExternalPersona(id: String): Promise<any[]> {
    const result = await this.HTTP.get("/external/persona/" + id);
    return result.data;
  }

  // get workstyle list
  async getWorkstyleList(data: any): Promise<any[]> {
    let url = `/persona?page=${data.page}`;
    if (data.search) {
      url = `/persona?page=${data.page}&search=${data.search}`;
    }
    const result = await this.HTTP.get(url);
    return result.data;
  }

  async getWorkstyleDetail(slug: any): Promise<any[]> {
    let url = `/persona/${slug}`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  async getCoworkerList(data: any): Promise<any[]> {
    let url = `/coworker?page=${data.page}`;
    if (data.search) {
      url = `/coworker?page=${data.page}&search=${data.search}`;
    }
    const result = await this.HTTP.get(url);
    return result.data;
  }
  // api/settings/on_board_process
  async getOnBoardProcess(): Promise<any[]> {
    let url = `/settings/on_board_process`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  async updateOnBoardProcess(data: any): Promise<any[]> {
    let url = `settings/save_configure_onboard_process`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  //
  async sendAffinityQuestion(): Promise<any[]> {
    let url = `affinity_questions/send_affinity_question`;
    const result = await this.HTTP.post(url);
    return result.data;
  }

  async updateTimezone(data: any): Promise<any[]> {
    let url = `settings/update_timezone_setting`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // api/coworker/profile/U04AD545A8Y
  async getCoworkerProfile(providerId: string): Promise<any[]> {
    let url = `/coworker/profile/${providerId}`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // settings/trigger_send_affinity_question
  async triggerSendAffinityQuestion(): Promise<any[]> {
    let url = `/settings/trigger_send_affinity_question`;
    const result = await this.HTTP.post(url);
    return result.data;
  }

  // api/teams/get_users_in_team
  async getUserInTeam(team_id: number): Promise<any[]> {
    let url = `teams/get_users_in_team?team_id=${team_id}`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // saveRole
  async saveRole(data: any): Promise<any[]> {
    let url = `/teams/set_user_role_in_team`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // getTeamDetail
  async getTeamDetail(team_id: number): Promise<any[]> {
    let url = `/teams/${team_id}`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // DASHBOARD
  async get_team_dashboard(params: any): Promise<any[]> {
    let url = `/dashboard/get_team_dashboard`;
    const result = await this.HTTP.get(url, { params: params });
    return result.data;
  }

  async get_company_dashboard(company_id: number): Promise<any[]> {
    let url = `/dashboard/get_company_dashboard?id=${company_id}`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  async get_culture_tracker(data: any): Promise<any[]> {
    let type = data?.type === 1 ? "team_id" : "company_id";
    let url = `/dashboard/get_culture_tracker?unit=${data?.time}&${type}=${data?.id}`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  async get_filter(): Promise<any[]> {
    let url = `/dashboard/get_filter`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  async get_manager_dashboard(): Promise<any[]> {
    let url = `/dashboard/get_manager_dashboard`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // lenses
  async get_entity_to_compare(search: any): Promise<any[]> {
    let url = `/lenses/get_entity_to_compare`;
    if (search) {
      url = `/lenses/get_entity_to_compare?search_value=${search}`;
    }
    const result = await this.HTTP.get(url);
    return result.data;
  }
  async getLenses(lesens: Lesens): Promise<any> {
    const result = await this.HTTP.get(
      `/lenses?from_id=${lesens.from_id}&from_type=${lesens.from_type}&to_id=${lesens.to_id}&to_type=${lesens.to_type}`
    );
    return result.data;
  }

  // admin access
  async getAdminUsers(): Promise<any[]> {
    let url = `/settings/get_users`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // settings/get_available_users
  async getAvailableUsers(): Promise<any[]> {
    let url = `/settings/get_available_users`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  async addAdminUser(data: any): Promise<any[]> {
    let url = `/settings/add_users`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  async removeAdminUser(data: any): Promise<any[]> {
    let url = `/settings/remove_user`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // non slack user
  async importUserNonSlack(data: any): Promise<any[]> {
    let url = `/people/import_users`;
    console.log(data);
    const result = await this.HTTPFILE.post(url, data);
    return result.data;
  }

  // people/create_people
  async createPeopleNonSlack(data: any): Promise<any[]> {
    let url = `people/create_people`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  async addPersonNonSlack(data: any): Promise<any[]> {
    let url = `people/add_person`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  async createAdminPeople(data: any): Promise<any[]> {
    let url = `settings/create_admin_user`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // delete people /people/delete_person
  async deletePersonNonSlack(data: any): Promise<any[]> {
    let url = `people/delete_person`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }
  // edit people /people/edit_person
  async editPersonNonSlack(data: any): Promise<any[]> {
    let url = `people/edit_person`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }
  // people/validate_person
  async validatePersonNonSlack(data: any): Promise<any[]> {
    let url = `people/validate_person`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // schedule
  async getScheduleQuestions(data: any): Promise<any[]> {
    let url = `/external/schedule/get_schedule_questions?history_id=${data.id}&provider_id=${data.provider_id}`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // external/schedule/submit_schedule_answers
  async submitScheduleAnswer(data: any): Promise<any[]> {
    let url = `external/schedule/submit_schedule_answers`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // pulses
  // external/mega_pulse/get_mega_pulse_question
  async getMegaPulseQuestion(data: any): Promise<any[]> {
    let url = `/external/mega_pulse/get_mega_pulse_question?provider_id=${data.provider_id}&mega_pulse_id=${data.id}`;
    const result = await this.HTTP.get(url);
    return result.data;
  }
  // external/mega_pulse/submit_mega_pulse_question
  async submitMegaPulseQuestion(data: any): Promise<any[]> {
    let url = `external/mega_pulse/submit_mega_pulse_question`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // api/external/get_workstyle_questions?provider_id=pg3UfHz8DMpVqYb7
  async getWorkstyleQuestions(data: any): Promise<any[]> {
    let url = `/external/get_workstyle_questions?provider_id=${data.provider_id}`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // external/submit_internal_answers
  async submitWorkstyleAnswer(data: any): Promise<any[]> {
    let url = `external/submit_internal_answers`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // Observers
  // get observers by team
  async getObserversByTeam(data: any): Promise<any[]> {
    let url = `/observer/get_observers_by_team?team_id=${data.team_id}`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // get user not observer by team
  async getUsersNotObserverTeam(data: any): Promise<any[]> {
    let url = `/observer/get_users_not_observer_team?team_id=${data.team_id}`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // add observer
  async addNewObserverToTeam(data: any): Promise<any[]> {
    let url = `observer/add_new_observer_to_team`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // observer/add_observers_to_team
  async addObserversToTeam(data: any): Promise<any[]> {
    let url = `observer/add_observers_to_team`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  //observer/remove_observer_in_team
  async removeObserverInTeam(data: any): Promise<any[]> {
    //
    let url = `observer/remove_observer_in_team`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // discover
  async getDiscover(): Promise<any[]> {
    let url = `/discover`;
    const result = await this.HTTP.get(url);
    return result.data;
  }
  // get all discover: /discover/get_all_feed
  async getAllFeed(): Promise<any[]> {
    let url = `/discover/get_all_feed`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // /pulse
  async getPulse(): Promise<any[]> {
    let url = `/pulse`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // reset persona data
  async resetPersonaData(): Promise<any[]> {
    let url = `/affinity_questions/reset_persona_data`;
    const result = await this.HTTP.post(url);
    return result.data;
  }

  // get random users has workstyles
  async getRandomUsersHasWorkstyles(): Promise<any[]> {
    let url = `/coworker/get_random_users_has_workstyles`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // get coworkers filter
  async getCoworkerFilter(): Promise<any[]> {
    let url = `/coworker/get_coworker_filter`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // get last time take workstyle
  async getLastTimeTakeWorkstyle(): Promise<any[]> {
    let url = `/profile/get_last_time_take_workstyle`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // company/create_company
  async createCompany(data: any): Promise<any[]> {
    let url = `company/create_company`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }
  // company/create_invitation
  async createInvitation(data: any): Promise<any[]> {
    let url = `company/create_invitation`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // get_invitation_info
  async getInvitationInfo(data: any): Promise<any[]> {
    let url = `/external/get_invitation_info`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // api/company/delete_company
  async deleteCompany(data: any): Promise<any[]> {
    let url = `/company/delete_company`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // api/company/get_invitations
  async getInvitations(): Promise<any[]> {
    let url = `/company/get_invitations`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // ai_chat/get_scenarios
  async getScenarios(): Promise<any[]> {
    let url = `/ai_chat/get_scenarios`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // ai_chat/get_conversation_histories
  async getConversationHistories(data?: any): Promise<any[]> {
    let url = `/ai_chat/get_conversation_histories`;
    const result = await this.HTTP.get(url, data);
    return result.data;
  }

  // ai_chat/get_conversation_history
  async getConversationHistory(id: string): Promise<any[]> {
    let url = `/ai_chat/get_conversation_history?conversation_id=${id}`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // ai_chat/start_conversation
  async startConversation(data?: any): Promise<any[]> {
    let url = `/ai_chat/start_conversation`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // ai_chat/get_users_to_ask_about
  async getUsersToAskAbout(data?: any): Promise<any[]> {
    let url = `/ai_chat/get_users_to_ask_about`;
    const result = await this.HTTP.get(url);
    console.log(result);
    return result.data;
  }

  // ai_chat/init_conversation_with_user
  async initConversationWithUser(data?: any): Promise<any[]> {
    let url = `/ai_chat/init_conversation_with_user`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // get_plan_packages
  async getPlanPackages(params: any): Promise<any[]> {
    let url = `/external/get_plan_packages`;
    const result = await this.HTTP.get(url, { params });
    return result.data;
  }

  // api/stripe/create_checkout_session
  async createCheckoutSession(data: any): Promise<any[]> {
    let url = `/stripe/create_checkout_session`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // /api/stripe/create_checkout_onboarding_session
  async createCheckoutOnboardingSession(data: any): Promise<any[]> {
    let url = `/stripe/create_checkout_onboarding_session`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // external/contact_us
  async contactUs(data: any): Promise<any[]> {
    let url = `/stripe/contact_us`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // api/company/revoke_invitation
  async revokeInvitation(data: any): Promise<any[]> {
    let url = `/company/revoke_invitation`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // external/unsubscribed_insight_email
  async unsubscribedInsightEmail(data: any): Promise<any[]> {
    let url = `/external/unsubscribed_insight_email`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // api/profile/save_basic_info
  async saveBasicInfo(data: any): Promise<any[]> {
    let url = `/profile/save_basic_info`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // api/profile/get_survey_process
  async getSurveyProcess(): Promise<any[]> {
    let url = `/profile/get_survey_process`;
    const result = await this.HTTP.get(url);
    return result.data;
  }

  // api/external/submit_survey_workstyle_answer
  async submitSurveyWorkstyleAnswer(data: any): Promise<any[]> {
    let url = `/external/submit_survey_workstyle_answer`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // profile/add_interests
  async addSurveyInterests(data: any): Promise<any[]> {
    let url = `/profile/add_interests`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // api/profile/save_goals
  async saveGoals(data: any): Promise<any[]> {
    let url = `/profile/save_goals`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // save_values
  async saveValues(data: any): Promise<any[]> {
    let url = `/profile/save_values`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }

  // /api/profile/add_interests
  async surveyInterests(data: any): Promise<any[]> {
    let url = `/profile/add_interests`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }
  // api/profile/remove_interests
  async removeSurveyInterests(data: any): Promise<any[]> {
    let url = `/profile/remove_interests`;
    const result = await this.HTTP.post(url, data);
    return result.data;
  }
}

export default axiosClient;
