import React from "react";
import ReactDOM from "react-dom";
import Router from "./router";
import "./styles/bootstrap.min.css";
import "./styles/lineicons.css";
import "./scss/main.scss";
import "./styles/main.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Gleap from "gleap";
import { RecoilRoot } from "recoil";
import { ClerkProvider } from "@clerk/clerk-react";
require("dotenv").config({ path: "./env/.env.dev" });
Gleap.initialize(process.env.REACT_APP_GLEAP_TOKEN);
ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ClerkProvider
        publishableKey={
          process.env.REACT_APP_PUBLIC_CLERK_PUBLISHABLE_KEY
            ? process.env.REACT_APP_PUBLIC_CLERK_PUBLISHABLE_KEY
            : "pk_test_YnJpZWYtY295b3RlLTQ4LmNsZXJrLmFjY291bnRzLmRldiQ"
        }>
        <Router />
      </ClerkProvider>
    </RecoilRoot>
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById("root")
);
