import * as React from "react";

type Props = {
  width?: number;
};
const SupenseLoadingLine = (props: Props) => {
  return <img src="/LoadingLines.gif" width={props.width ? props.width : 100} alt="loading" />;
};

export default SupenseLoadingLine;
