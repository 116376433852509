import _ from "lodash";
import React from "react";

type Props = {
  label?: string;
  placeholder?: string;
  onChange?: Function;
  onBlur?: Function;
  error?: string;
  defaultValue?: string;
  children?: any;
  readonly?: boolean;
  rows?: number;
};

const PlainAdminFormTextarea = (props: Props) => {
  return (
    <div className={`input-style-1 ${props.error ? "was-invalied" : ""}`}>
      {props.label && <label> {props.label}</label>}
      <textarea
        className="bg-transparent"
        placeholder={props.placeholder}
        rows={props.rows ? props.rows : 5}
        spellCheck={false}
        onChange={(e: any) => {
          props.onChange? props.onChange(e.target.value) : _.noop();
        }}
        defaultValue={props.defaultValue}
        ></textarea>
      {props.error && <div className="invalid-feedback d-block">{props.error}</div>}
      {props.children ? props.children : null}
    </div>
  );
};
export default PlainAdminFormTextarea;
