import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import React from "react";

type Props = {
  title?: string;
  type: string;
  backgroundColor?: string;
  children?: React.ReactNode;
  onClick?: Function;
  disabled?: boolean;
  isLoading?: boolean;
  url?: string;
  target?: string;
};

const PlainAdminButton = (props: Props) => {
  const hasClick = () => {
    return !props.disabled && !props.isLoading;
  };
  const handleClick = (e: any) => {
    e.preventDefault();
    if (props.onClick && hasClick()) {
      props.onClick();
    }
  };
  return (
    <>
      <a
        href={props.url ? props.url : "#"}
        target={props.target ? props.target : "_self"}
        onClick={(e: any) => handleClick(e)}
        className={`main-btn ${props.type} ${(props.disabled || props.isLoading) && "deactive-btn"} btn-hover`}
        style={{ backgroundColor: props.backgroundColor }}>
        {props.isLoading && <CircularProgress size={20} className="ml-2" color="primary" />}{" "}
        {props.children ? props.children : props.title}
      </a>
    </>
  );
};
export default PlainAdminButton;
