import React, { useEffect, useState } from "react";
import _ from "lodash";
import notify from "../../notify";
import Helper from "../../utils/helper";
import PlainAdminModal from "../../common/PlainAdmin-Modal";
import PlainAdminFormInput from "../../common/PlainAdmin-FormInput";
import PlainAdminFormSelect from "../../common/PlainAdmin-FormSelect";
import { useRecoilState } from "recoil";
import { token, userProfile } from "../../recoil/userProfile";
import axiosClient from "../../axiosClient";
import PlainAdminFormTextarea from "../../common/PlainAdmin-Textarea";

type Props = {
  onChange?: Function;
  isAddProp?: boolean;
  teams?: any;
};

const ContactUs = (props: Props) => {
  const defaultPerson = { phone: "", organization_size: "", message: "" };
  const [isEdit] = useState(props.isAddProp);
  const [people]: any = useState([]);
  const [errors, setErrors]: any = useState({
    email: [],
    name: [],
    phone: [],
    organization_name: [],
    organization_size: [],
  });
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [person, setPerson]: any = useState(defaultPerson);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [currentToken] = useRecoilState(token);
  const API = new axiosClient(currentToken);
  const [currentUser] = useRecoilState(userProfile);

  useEffect(() => {
    setPerson(defaultPerson);
  }, [count]);

  const contactUs = async () => {
    if (!isValidEmail) {
      validateEmail();
      console.log(errors);
      return;
    }
    setIsLoading(true);
    await API.contactUs(person)
      .then((res: any) => {
        notify.notify("Send contact success!");
        props.onChange ? props.onChange() : _.noop();
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
        notify.error("Have an error when send contact!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const updateName = (value: any) => {
    person.name = value;
    setPerson(person);
  };
  const updateEmail = (value: any) => {
    person.email = value;
    setPerson(person);
  };
  const updatePhone = (value: any) => {
    person.phone = value;
    setPerson(person);
  };
  const updateOrganizationName = (value: any) => {
    person.organization_name = value;
    setPerson(person);
  };
  const updateOrganizationSize = (value: any) => {
    person.organization_size = value;
    setPerson(person);
  };
  const updateMessage = (value: any) => {
    person.message = value;
    setPerson(person);
  };

  const validateEmail = (key = "all") => {
    setIsValidEmail(true);
    const errorsClone = _.cloneDeep(errors);
    // if (key === "all" || key === "email") {
    //   if (!person.email) {
    //     errorsClone.email[0] = "Email can't be blank";
    //     setIsValidEmail(false);
    //   } else if (!Helper.validateEmail(person.email)) {
    //     errorsClone.email[0] = "Email is not valid";
    //     setIsValidEmail(false);
    //   } else {
    //     errorsClone.email = [];
    //   }
    // }
    // if (key === "all" || key === "name") {
    //   if (!person.name) {
    //     errorsClone.name[0] = "Name can't be blank";
    //     setIsValidEmail(false);
    //   } else {
    //     errorsClone.name = [];
    //   }
    // }
    if (key === "all" || key === "phone") {
      if (!person.phone) {
        errorsClone.phone[0] = "Phone can't be blank";
        setIsValidEmail(false);
      } else {
        errorsClone.phone = [];
      }
    }
    // if (key === "all" || key === "organization_name") {
    //   if (!person.organization_name) {
    //     errorsClone.organization_name[0] = "Organization name can't be blank";
    //     setIsValidEmail(false);
    //   } else {
    //     errorsClone.organization_name = [];
    //   }
    // }

    if (key === "all" || key === "organization_size") {
      if (!person.organization_size) {
        errorsClone.organization_size[0] = "Organization size can't be blank";
        setIsValidEmail(false);
      } else {
        errorsClone.organization_size = [];
      }
    }
    setErrors(errorsClone);
  };
  return (
    <PlainAdminModal
      title={"Contact Us"}
      onClose={() => {
        props.onChange ? props.onChange() : _.noop();
      }}
      className="modal-contact"
      isLoadingSubmitButton={isLoading}
      onSubmit={() => {
        contactUs();
      }}>
      {!loading && (
        <div className="row">
          <div className="col-12 col-md-12">
            <p className="mb-30">
              Hi {currentUser?.first_name}, drop us your details below and we’ll reach out to discuss an Enterprise plan
              with you.
            </p>
          </div>
          {/* <div className="col-12 col-md-12">
            <PlainAdminFormInput
              label="Name"
              onBlur={(e: any) => validateEmail("name")}
              onChange={(e: any) => updateName(e)}
              error={errors?.name && errors.name[0]}
              type={"text"}
            />
          </div>
          <div className="col-12 col-md-6">
            <PlainAdminFormInput
              label="Person email"
              // placeholder="Person email"
              onChange={(e: any) => updateEmail(e)}
              onBlur={(e: any) => validateEmail("email")}
              error={errors?.email && errors.email[0]}
              type={"email"}
            />
          </div> */}
          <div className="col-12 col-md-6">
            <PlainAdminFormInput
              label="Phone No"
              onBlur={(e: any) => validateEmail("phone")}
              onChange={(e: any) => updatePhone(e)}
              error={errors?.phone && errors.phone[0]}
              type={"text"}
            />
          </div>
          {/* <div className="col-12 col-md-6">
            <PlainAdminFormInput
              label="Organization Name"
              onBlur={(e: any) => validateEmail("organization_name")}
              onChange={(e: any) => updateOrganizationName(e)}
              error={errors?.organization_name && errors.organization_name[0]}
              type={"text"}
            />
          </div> */}
          <div className="col-12 col-md-6">
            <PlainAdminFormInput
              label="Organization size"
              onBlur={(e: any) => validateEmail("organization_size")}
              onChange={(e: any) => updateOrganizationSize(e)}
              error={errors?.organization_size && errors.organization_size[0]}
              type={"text"}
            />
          </div>
          <div className="col-12 col-md-12">
            <PlainAdminFormTextarea
              label="Message (Optional)"
              rows={3}
              onBlur={(e: any) => validateEmail("message")}
              onChange={(e: any) => updateMessage(e)}
              error={errors?.message && errors.message[0]}
            />
          </div>
        </div>
      )}
    </PlainAdminModal>
  );
};

export default ContactUs;
