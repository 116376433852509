import _ from "lodash";
import React from "react";
import PlainAdminButton from "./PlainAdmin-Button";

type Props = {
  title: string;
  body?: string;
  children?: React.ReactNode;
  onClose: Function;
  closeText?: string;
  onSubmit: Function;
  submitText?: string;
  hiddleFooter?: boolean;
  hiddenCloseButton?: boolean;
  hiddleSubmitButton?: boolean;
  size?: string;
  contentClass?: string;
  isLoadingSubmitButton?: boolean;
  scrollableContent?: boolean;
  className?: string;
};

const PlainAdminModal = (props: Props) => {
  return (
    <>
      <div className="warning-modal">
        <div
          className={`modal fade show d-block ${props.className ? props.className : ""}`}
          id="ModalFour"
          tabIndex={-1}
          aria-hidden="true">
          <div
            className={`modal-dialog ${
              props.scrollableContent ? "modal-dialog-scrollable" : ""
            } modal-dialog-centered ${props.size && "modal-" + props.size}`}>
            <div className="modal-content card-style">
              <div className="modal-header px-0 border-0">
                <h5 className="text-bold">{props.title}</h5>
                <button className="border-0 bg-transparent h1" data-bs-dismiss="modal" onClick={() => props.onClose()}>
                  <i className="lni lni-cross-circle"></i>
                </button>
              </div>
              <div className="modal-body px-0">
                <div className={`content ${props.contentClass ? props.contentClass : "mb-30"}`}>
                  {props?.children ? (
                    props.children
                  ) : (
                    <p className="text-sm">
                      {props.body ? props.body : "Please fill body prop to display body of modal"}
                    </p>
                  )}
                </div>
                {!props.hiddleFooter && (
                  <div className="action d-flex flex-wrap justify-content-end">
                    {!props.hiddenCloseButton && (
                      <button
                        data-bs-dismiss="modal"
                        onClick={() => (props.onClose ? props.onClose() : _.noop())}
                        className="main-btn btn-sm danger-btn-outline btn-hover m-1">
                        {props.closeText ? props.closeText : "Cancel"}
                      </button>
                    )}
                    {!props.hiddleSubmitButton && (
                      <PlainAdminButton
                        type="btn-sm primary-btn m-1"
                        title={props.submitText ? props.submitText : "Submit"}
                        onClick={() => (props.onSubmit ? props.onSubmit() : _.noop())}
                        isLoading={props.isLoadingSubmitButton}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="option-overlay show"></div>
      </div>
    </>
  );
};

export default PlainAdminModal;
