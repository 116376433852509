import React from "react";
import { LayoutProps } from ".";


export default class Body extends React.Component<LayoutProps> {
    render(){
        return <div className='content'>
            {this.props.children}
        </div>
    }

}