import axios from "axios";

const HTTP = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://127.0.0.1:5000/api",
});

class Auth {
  saveToken(code: any) {
    return HTTP.post("/auth/token", { code: code }).then((result) => result.data);
  }

  // non-slack auth
  async signup(data: any): Promise<any[]> {
    let url = `/otp/create_company`;
    const result = await HTTP.post(url, data);
    return result.data;
  }

  ///otp
  async getOtp(data: any): Promise<any[]> {
    let url = `/otp`;
    const result = await HTTP.post(url, data);
    return result.data;
  }

  async verify(data: any): Promise<any[]> {
    let url = `/otp/verify`;
    const result = await HTTP.post(url, data);
    return result.data;
  }
}

const auth = new Auth();
export default auth;
