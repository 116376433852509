import React, { useEffect, useState } from "react";
import _ from "lodash";
import LensesIcon from "../svg/LensesIcon";
import PeopleIcon from "../svg/PeopleIcon";
import TeamIcon from "../svg/TeamIcon";
import SettingIcon from "../svg/SettingIcon";
import ProfileIcon from "../svg/ProfileIcon";
import QuestionnireIcon from "../svg/QuestionnaireIcon";
import DashboardIcon from "../svg/DashboardIcon";
import MyWorkstyleIcon from "../svg/MyWorkstyleIcon";
import authService from "../auth.service";
import { USER_ROLE } from "../utils/constant";
import ObserverIcon from "../svg/ObserverIcon";
import DiscoverIcon from "../svg/DiscoverIcon";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import { useRecoilState } from "recoil";
import { pulseStarted } from "../recoil/pulseState";
import { userProfile } from "../recoil/userProfile";
import ChatIcon from "../svg/ChatIcon";
import PlainAdminButton from "../common/PlainAdmin-Button";
import { accountFeatures, companyPlan, showContactUs } from "../recoil/company";
import Helper from "../utils/helper";

export type LayoutProps = RouteComponentProps & {
  isLoggedIn?: boolean;
  children?: any;
};
const Menu = (props: LayoutProps) => {
  const history = useHistory();
  const [currentPulseStarted] = useRecoilState(pulseStarted);
  const [currentUser] = useRecoilState(userProfile);
  const [companyFeatures, setCompanyFeatures] = useRecoilState(accountFeatures);
  const [currentPlan] = useRecoilState(companyPlan);
  const [showCurrentContactUs, setShowCurrentContactUs] = useRecoilState(showContactUs);
  let [isPulseStarted, setIsPulseStarted]: any = useState(authService.getAffinityStarted());
  useEffect(() => {
    if (currentPulseStarted !== undefined) {
      setIsPulseStarted(currentPulseStarted ? (isPulseStarted = "true") : (isPulseStarted = "false"));
    }
  }, [currentPulseStarted]);
  let path = window.location.pathname;
  let currentMenu = "";
  const currentUserRole = currentUser ? currentUser.role : authService.getUserRole();

  const isContact = currentPlan === "pro" ? true : false;

  const currentPlanName = () => {
    if (currentPlan === "starter") {
      return "Starter";
    }

    if (currentPlan === "pro") {
      return "Pro";
    }
    if (currentPlan === "premium") {
      return "Premium";
    }
    return "";
  };

  if (currentUser?.provider_id === path.replace("/p/", "")) {
    currentMenu = "profile";
  }
  if (_.includes(path, "/dashboard")) {
    currentMenu = "dashboard";
  }
  if (_.includes(path, "/teams")) {
    currentMenu = "teams";
  }
  if (_.includes(path, "/people")) {
    currentMenu = "people";
  }
  if (_.includes(path, "/settings")) {
    currentMenu = "settings";
  }
  if (_.includes(path, "/settings")) {
    currentMenu = "settings";
  }
  if (_.includes(path, "/coworkers")) {
    currentMenu = "coworkers";
  }
  if (_.includes(path, "/workstyle-list")) {
    currentMenu = "workstyle-list";
  }
  if (_.includes(path, "/lenses")) {
    currentMenu = "lenses";
  }
  if (_.includes(path, "/questionnaire-answer")) {
    currentMenu = "questionnaire-answer";
  }
  if (_.includes(path, "/admin/questions")) {
    currentMenu = "admin-questions";
  }
  if (_.includes(path, "/admin/dimensions")) {
    currentMenu = "admin-dimensions";
  }
  if (_.includes(path, "/admin/settings")) {
    currentMenu = "admin-settings";
  }
  if (_.includes(path, "/my-workstyle")) {
    currentMenu = "my-workstyle";
  }
  if (_.includes(path, "/observer")) {
    currentMenu = "observer";
  }
  if (_.includes(path, "/discover")) {
    currentMenu = "discover";
  }

  if (_.includes(path, "/pulses")) {
    currentMenu = "pulses";
  }
  if (_.includes(path, "/meeting")) {
    currentMenu = "metting";
  }

  if (_.includes(path, "/groq")) {
    currentMenu = "groq";
  }

  return (
    <div>
      <nav className="sidebar-nav">
        <h4 className="menu-name">About You</h4>
        <ul className="mb-30" key={currentPulseStarted ? "pulsed" : "not-pulsed"}>
          <li className={`nav-item ${currentMenu === "discover" ? "active" : ""}`}>
            <a onClick={() => history.push("/discover")} className="cursor-pointer">
              <span className="icon">
                <DiscoverIcon width={20} height={20} />
              </span>
              <span className="text">Discover</span>
            </a>
          </li>
          {/*
           */}
          <li className={`nav-item ${currentMenu === "profile" ? "active" : ""}`}>
            <a onClick={() => history.push(`/p/${currentUser?.provider_id}`)} className="cursor-pointer">
              <span className="icon">
                <ProfileIcon width={20} height={20} />
              </span>
              <span className="text">My Profile</span>
            </a>
          </li>
          {isPulseStarted === "true" && (
            <li className={`nav-item ${currentMenu === "pulses" ? "active" : ""}`}>
              <a onClick={() => history.push(`/pulses`)} className="cursor-pointer">
                <span className="icon">
                  <QuestionnireIcon width={20} height={20} />
                </span>
                <span className="text">Pulses</span>
              </a>
            </li>
          )}
          <li className={`nav-item ${currentMenu === "my-workstyle" ? "active" : ""}`}>
            <a onClick={() => history.push("/my-workstyle")} className="cursor-pointer">
              <span className="icon">
                <MyWorkstyleIcon width={20} height={20} />
              </span>
              <span className="text">My Workstyle</span>
            </a>
          </li>
          {/* <li className={`nav-item ${currentMenu === "workstyle-list" ? "active" : ""}`}>
            <a href="/workstyle-list">
              <span className="icon">
                <WorkstyleIcon width={20} height={20} />
              </span>
              <span className="text">Workstyles</span>
            </a>
          </li> */}

          <li className={`nav-item ${currentMenu === "coworkers" ? "active" : ""}`}>
            <a onClick={() => history.push("/coworkers")} className="cursor-pointer">
              <span className="icon">
                <PeopleIcon width={20} height={20} />
              </span>
              <span className="text">Co-workers</span>
            </a>
          </li>

          {currentUserRole === USER_ROLE.OBSERVER && (
            <li className={`nav-item ${currentMenu === "observer" ? "active" : ""}`}>
              <a onClick={() => history.push("/observer")} className="cursor-pointer">
                <span className="icon">
                  <ObserverIcon width={24} height={24} />
                </span>
                <span className="text">Observer</span>
              </a>
            </li>
          )}
        </ul>
        {currentUserRole === USER_ROLE.ADMIN && (
          <>
            <h4 className="menu-name">Admin</h4>
            <ul>
              {Helper.isFeature("dashboard", companyFeatures) && (
                <li className={`nav-item ${currentMenu === "dashboard" ? "active" : ""}`}>
                  <a onClick={() => history.push("/dashboard")} className="cursor-pointer">
                    <span className="icon">
                      <DashboardIcon width={20} height={20} />
                    </span>
                    <span className="text">Dashboard</span>
                  </a>
                </li>
              )}
              {Helper.isFeature("harmonics_AI", companyFeatures) && (
                <li className={`nav-item ${currentMenu === "groq" ? "active" : ""}`}>
                  <a onClick={() => history.push("/groq")} className="cursor-pointer">
                    <span className="icon">
                      <ChatIcon width={20} height={20} />
                    </span>
                    <span className="text">AI Manager Coach</span>
                  </a>
                </li>
              )}
              {Helper.isFeature("lenses", companyFeatures) && (
                <li className={`nav-item ${currentMenu === "lenses" ? "active" : ""}`}>
                  <a onClick={() => history.push("/lenses")} className="cursor-pointer">
                    <span className="icon">
                      <LensesIcon width={20} height={20} />
                    </span>
                    <span className="text">Lenses</span>
                  </a>
                </li>
              )}
              {Helper.isFeature("people", companyFeatures) && (
                <li className={`nav-item ${currentMenu === "people" ? "active" : ""}`}>
                  <a onClick={() => history.push("/people")} className="cursor-pointer">
                    <span className="icon">
                      <PeopleIcon width={20} height={20} />
                    </span>
                    <span className="text">People</span>
                  </a>
                </li>
              )}
              {Helper.isFeature("teams", companyFeatures) && (
                <li className={`nav-item ${currentMenu === "teams" ? "active" : ""}`}>
                  <a onClick={() => history.push("/teams")} className="cursor-pointer">
                    <span className="icon">
                      <TeamIcon width={20} height={20} />
                    </span>
                    <span className="text">Teams</span>
                  </a>
                </li>
              )}
              {Helper.isFeature("settings", companyFeatures) && (
                <li className={`nav-item ${currentMenu === "settings" ? "active" : ""}`}>
                  <a onClick={() => history.push("/settings")} className="cursor-pointer">
                    <span className="icon">
                      <SettingIcon width={20} height={20} />
                    </span>
                    <span className="text">Settings</span>
                  </a>
                </li>
              )}
            </ul>
          </>
        )}
        {currentUserRole === USER_ROLE.MANAGER && (
          <>
            <h4 className="menu-name">Manager</h4>
            <ul style={{ marginBottom: 50 }}>
              {Helper.isFeature("dashboard", companyFeatures) && (
                <li className={`nav-item ${currentMenu === "dashboard" ? "active" : ""}`}>
                  <a onClick={() => history.push("/dashboard")} className="cursor-pointer">
                    <span className="icon">
                      <DashboardIcon width={20} height={20} />
                    </span>
                    <span className="text">Dashboard</span>
                  </a>
                </li>
              )}
              {Helper.isFeature("harmonics_AI", companyFeatures) && (
                <li className={`nav-item ${currentMenu === "groq" ? "active" : ""}`}>
                  <a onClick={() => history.push("/groq")} className="cursor-pointer">
                    <span className="icon">
                      <ChatIcon width={20} height={20} />
                    </span>
                    <span className="text">AI Manager Coach</span>
                  </a>
                </li>
              )}
              {Helper.isFeature("lenses", companyFeatures) && (
                <li className={`nav-item ${currentMenu === "lenses" ? "active" : ""}`}>
                  <a onClick={() => history.push("/lenses")} className="cursor-pointer">
                    <span className="icon">
                      <LensesIcon width={20} height={20} />
                    </span>
                    <span className="text">Lenses</span>
                  </a>
                </li>
              )}
              {Helper.isFeature("people", companyFeatures) && (
                <li className={`nav-item ${currentMenu === "people" ? "active" : ""}`}>
                  <a onClick={() => history.push("/people")} className="cursor-pointer">
                    <span className="icon">
                      <PeopleIcon width={20} height={20} />
                    </span>
                    <span className="text">People</span>
                  </a>
                </li>
              )}
            </ul>
          </>
        )}

        {currentUserRole === USER_ROLE.ADMIN && (
          <>
            {!isContact ? (
              <div className="ml-25 mr-25">
                <hr />
                <p className="text-sm-12 text-center">
                  You are currently on the {currentPlanName()} Plan, upgrade to access more features.
                </p>
              </div>
            ) : (
              <></>
            )}
            <ul>
              {!Helper.isFeature("harmonics_AI", companyFeatures) && (
                <li className={`nav-item nav-item-disabled`}>
                  <a className="text-gray">
                    <span className="icon">
                      <ChatIcon width={20} height={20} />
                    </span>
                    <span className="text">AI Manager Coach</span>
                  </a>
                </li>
              )}
              {!Helper.isFeature("dashboard", companyFeatures) && (
                <li className={`nav-item nav-item-disabled`}>
                  <a className="text-gray">
                    <span className="icon">
                      <DashboardIcon width={20} height={20} />
                    </span>
                    <span className="text">Dashboard</span>
                  </a>
                </li>
              )}
              {!Helper.isFeature("lenses", companyFeatures) && (
                <li className={`nav-item nav-item-disabled`}>
                  <a className="text-gray">
                    <span className="icon">
                      <LensesIcon width={20} height={20} />
                    </span>
                    <span className="text">Lenses</span>
                  </a>
                </li>
              )}
            </ul>
            {isContact ? (
              <div className="text-center mt-25">
                <div className="ml-25 mr-25">
                  <hr />
                  <p className="text-sm-11 text-center">
                    You are currently on the Pro Plan, to upgrade to an Enterprise Plan reach out to our team for a
                    tailored quote.
                  </p>
                </div>
                <div className="mt-10">
                  <PlainAdminButton
                    type={"btn-sm primary-btn"}
                    title="Contact Us"
                    onClick={() => {
                      setShowCurrentContactUs(true);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="text-center mt-25">
                <div>
                  <PlainAdminButton
                    type={"btn-sm primary-btn"}
                    title="Upgrade"
                    onClick={() => {
                      history.push("/pricing");
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </nav>
    </div>
  );
};

export default withRouter(Menu);
