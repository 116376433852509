type Props = {
  width: number;
  height: number;
};

const TeamIcon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={props.width || 35} height={props.height || 35}>
      <path d="M3,11H11V3H3M3,21H11V13H3M13,21H21V13H13M13,3V11H21V3" />
    </svg>
  );
};
export default TeamIcon;
