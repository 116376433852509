type Props = {
  width: number;
  height: number;
};

const LensesIcon = (props: Props) => {
  return (
    <svg
      width={props.width || 35}
      height={props.height || 35}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.20529 17.4997C3.20529 18.0186 3.46965 18.4938 3.99836 19.4442L6.46179 23.8727L9.06519 28.2203C9.62394 29.1534 9.90332 29.6199 10.3526 29.8793C10.802 30.1387 11.3457 30.1474 12.4332 30.1648L17.5 30.2456L22.5668 30.1648H22.5669C23.6543 30.1474 24.198 30.1387 24.6474 29.8793C25.0967 29.6199 25.3761 29.1534 25.9348 28.2203L28.5382 23.8727L31.0017 19.4442C31.5304 18.4938 31.7947 18.0186 31.7947 17.4997C31.7947 16.9809 31.5304 16.5057 31.0016 15.5552L28.5382 11.1268L25.9348 6.77922C25.3761 5.84612 25.0967 5.37958 24.6474 5.12015C24.198 4.86073 23.6543 4.85206 22.5668 4.83472L17.5 4.75391L12.4332 4.83472C11.3457 4.85206 10.802 4.86073 10.3526 5.12016C9.90332 5.37958 9.62394 5.84613 9.06519 6.77922L6.46179 11.1268L3.99836 15.5552C3.46965 16.5057 3.20529 16.9809 3.20529 17.4997ZM17.5 21.8747C19.9163 21.8747 21.875 19.916 21.875 17.4997C21.875 15.0835 19.9163 13.1247 17.5 13.1247C15.0838 13.1247 13.125 15.0835 13.125 17.4997C13.125 19.916 15.0838 21.8747 17.5 21.8747Z"
        fill="#FFFFFF"
        fillOpacity="0.25"
      />
      <path
        d="M3.99836 19.4432C3.46965 18.4928 3.20529 18.0175 3.20529 17.4987C3.20529 16.9799 3.46965 16.5046 3.99836 15.5542L6.46179 11.1258L9.06519 6.77818C9.62394 5.84508 9.90332 5.37853 10.3526 5.11911C10.802 4.85969 11.3457 4.85102 12.4332 4.83368L17.5 4.75286L22.5668 4.83368C23.6543 4.85102 24.198 4.85969 24.6474 5.11911C25.0967 5.37853 25.3761 5.84508 25.9348 6.77818L28.5382 11.1258L31.0016 15.5542C31.5304 16.5046 31.7947 16.9799 31.7947 17.4987C31.7947 18.0175 31.5304 18.4928 31.0017 19.4432L28.5382 23.8716L25.9348 28.2192C25.3761 29.1523 25.0967 29.6189 24.6474 29.8783C24.198 30.1377 23.6543 30.1464 22.5668 30.1637L17.5 30.2445L12.4332 30.1637C11.3457 30.1464 10.802 30.1377 10.3526 29.8783C9.90332 29.6189 9.62394 29.1523 9.06519 28.2192L6.46179 23.8716L3.99836 19.4432Z"
        stroke="#FFFFFF"
        strokeWidth="1.2"
      />
      <circle cx="17.5" cy="17.5" r="4.375" stroke="#FFFFFF" strokeWidth="1.2" fill="none" />
    </svg>
  );
};
export default LensesIcon;
