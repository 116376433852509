import { BrowserRouter, Switch, Route } from "react-router-dom";
import React, { Suspense, useEffect } from "react";

import Layout from "./layout";
import AuthService from "./auth.service";
import _ from "lodash";
import authService from "./auth.service";
import { SHOW_WORKSTYLE_NOTIFICATION, USER_ROLE } from "./utils/constant";
import { useAuth } from "@clerk/clerk-react";
import SupenseLoadingLine from "./components/SuspenseLoadingLine";
import axiosClient from "./axiosClient";
import { useRecoilState } from "recoil";
import { token, userProfile, affinityTeamId } from "./recoil/userProfile";
import PrivateLayout from "./layout/PrivateLayout";
import { accountFeatures, companyPlan, isTrial } from "./recoil/company";
import Helper from "./utils/helper";

const isPublicRoute = (path: string) => {
  const publicRoutes = [
    "/",
    "/questionnaire",
    "/login",
    "/workstyle",
    "/persona",
    "/signup",
    "/schedule",
    "/privacy-policy",
    "/terms",
    "/pulse",
  ];
  return publicRoutes.includes(path);
};

const SettingPage = React.lazy(() => import("./pages/settings"));
const HomePage = React.lazy(() => import("./pages/home"));
const AsyncProfilePage = React.lazy(() => import("./pages/profile/index"));
const AsyncProfilePageOther = React.lazy(() => import("./pages/profile/other"));
const CoworkerProfile = React.lazy(() => import("./pages/coworker/profile"));
const TeamsPage = React.lazy(() => import("./pages/teams/index"));
const PeoplePage = React.lazy(() => import("./pages/people/index"));
const LensesPage = React.lazy(() => import("./pages/lenses"));
const QuestionnaireAnswer = React.lazy(() => import("./pages/questionnaire-answer"));
const PublicQuestionnaire = React.lazy(() => import("./pages/questionnaire"));
const WorkstyleTime = React.lazy(() => import("./pages/questionnaire/workstyle-time"));
const WorkStyle = React.lazy(() => import("./pages/questionnaire/workstyle"));
const ExternalPersona = React.lazy(() => import("./pages/questionnaire/persona"));
const IdentifyQuestionnaire = React.lazy(() => import("./pages/questionnaire/identify"));
const LoginPage = React.lazy(() => import("./pages/login/index"));
const WorkstyleList = React.lazy(() => import("./pages/workstyles-list"));
const WorkstyleDetailPage = React.lazy(() => import("./pages/workstyle-detail-page"));
const CoWorkerPage = React.lazy(() => import("./pages/coworker/index"));
const NotFound = React.lazy(() => import("./pages/404"));
const LearnMore = React.lazy(() => import("./pages/learn-more"));
const MyWorkstyle = React.lazy(() => import("./pages/my-workstyle"));
const Observers = React.lazy(() => import("./pages/admin/observer"));
const Discover = React.lazy(() => import("./pages/discover"));
const Feed = React.lazy(() => import("./pages/feed"));
const WorkstyleNotification = React.lazy(() => import("./components/workstyle-notification"));
const Pulses = React.lazy(() => import("./pages/pulses"));
const TermsPage = React.lazy(() => import("./pages/terms"));
const PolicyPage = React.lazy(() => import("./pages/policy"));
const MettingPage = React.lazy(() => import("./pages/metting"));
const DashboardPage = React.lazy(() => import("./pages/admin/dashboard"));
const TeamOverview = React.lazy(() => import("./pages/teams/overview"));
const Register = React.lazy(() => import("./pages/register/index"));
const ScheduleQuestion = React.lazy(() => import("./pages/schedule/index"));
const PulseQuestion = React.lazy(() => import("./pages/schedule/pulse"));
const OrganizationPage = React.lazy(() => import("./pages/organization/index"))
const AcceptPageAsync = React.lazy(() => import("./pages/accept/index"));
const GropPageAsync = React.lazy(() => import("./pages/groq/index"));
const PricingPageAsync = React.lazy(() => import("./pages/pricing/index"));
const CheckoutSuccessPageAsync = React.lazy(() => import("./pages/pricing/checkout-success"));
const UnsubscribePageAsync = React.lazy(() => import("./pages/email/unsubscribe"));
// const TrialPageAsync = React.lazy(() => import("./pages/pricing/trial"));

const Router = () => {
  const API = new axiosClient();
  const { getToken } = useAuth();
  const [currentUser, setCurrentUser] = React.useState<any | undefined>(undefined);
  const [recoilCurrentUser, setRecoilCurrentUser] = useRecoilState(userProfile);
  const [currentAffinityTeamId, setAffinityTeamId] = useRecoilState(affinityTeamId);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [afterCheckLogin, setAfterCheckLogin] = React.useState(false);
  const [showWorkstyleNotification, setShowWorkstyleNotification] = React.useState(false);
  const [currentToken, setCurrentToken] = useRecoilState(token);
  const [companyFeatures, setCompanyFeatures] = useRecoilState(accountFeatures);
  const [currentPlan, setCurrentPlan] = useRecoilState(companyPlan);
  const [isCurrentTrial, setIsCurrentTrial] = useRecoilState(isTrial);
  useEffect(() => {
    getClerkToken();
  }, []);
  const template = process.env.REACT_APP_PUBLIC_CLERK_JWT_TEMPLATE
    ? process.env.REACT_APP_PUBLIC_CLERK_JWT_TEMPLATE
    : "Login";
  const getClerkToken = async () => {
    const token = await getToken({ template: template });
    if (token) {
      setCurrentToken(token);
      AuthService.setToken(token);
      getCurrentUser(token);
    } else {
      AuthService.setToken("");
    }
    token ? setIsLoggedIn(true) : setIsLoggedIn(false);
    if (!isPublicRoute && !token) {
      window.location.href = "/login";
    }
  };

  const getCurrentUser = async (token: string) => {
    const _currentUser = await API.getCurrentProfileInfo(token);
    if (_currentUser) {
      setCurrentUser(_currentUser.profile);
      setRecoilCurrentUser(_currentUser.profile);
      setAffinityTeamId(_currentUser.affinity_team_id);
      if (_currentUser.companies.length > 0) {
        setCompanyFeatures(_currentUser.companies[0].account_features);
        setCurrentPlan(_currentUser.companies[0].company_plan);
        setIsCurrentTrial(_currentUser.companies[0].is_trail);
      }
      authService.exchangeAuthenticationNonSlack(_currentUser);
      if (authService.getShowNotificationWorkstyle() !== SHOW_WORKSTYLE_NOTIFICATION.SHOWRED) {
        authService.setShowNotificationWorkstyle(_.toString(_currentUser.profile.profile_question_state));
      }
      if (!_currentUser.is_process_payment && window.location.pathname !== "/organization") {
        window.location.href = "/organization";
      }
      if (
        authService.getShowNotificationWorkstyle() === SHOW_WORKSTYLE_NOTIFICATION.NO_ANSWER &&
        window.location.pathname !== "/organization" &&
        window.location.pathname !== "/workstyle-time"
      ) {
        setShowWorkstyleNotification(true);
      }
    }
    setAfterCheckLogin(true);
  };

  const hasPermissionAdminAndManager = _.find(["admin", "manager"], function (item) {
    return item === recoilCurrentUser?.role;
  });

  const hasPermissionAdmin = recoilCurrentUser?.role === USER_ROLE.ADMIN;

  const isObserver = USER_ROLE.OBSERVER === recoilCurrentUser?.role;

  return (
    <BrowserRouter>
      {/* <Layout currentUser={currentUser} isLoggedIn={isLoggedIn}> */}
      {window.location.pathname === "/organization" ? (
        <Route exact path={["/organization"]}>
          <PrivateLayout currentUser={currentUser} isLoggedIn={isLoggedIn}>
            <Suspense fallback={<SupenseLoadingLine />}>
              <Switch>
                <Route path="/organization" component={OrganizationPage}></Route>
              </Switch>
            </Suspense>
          </PrivateLayout>
        </Route>
      ) : !isLoggedIn ? (
        <Route
          exact
          path={[
            "/",
            "/login",
            "/signup",
            "/questionnaire",
            "/workstyle-time",
            "/workstyle/:providerId",
            "/persona/:code",
            "/terms",
            "/privacy-policy",
            "/schedule/:provider_id/:id",
            "/pulse/:provider_id/:id",
            "/questionnaire/:provider_id",
            "/accept",
            "/pricing",
            "/pay-success",
            "/unsubscribe",
          ]}>
          <Layout currentUser={currentUser} isLoggedIn={isLoggedIn}>
            <Suspense fallback={<SupenseLoadingLine />}>
              <Switch>
                {/* Public Routes */}
                <Route exact path="/" component={HomePage} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/signup" component={Register} />
                <Route exact path="/questionnaire" component={PublicQuestionnaire} />
                <Route exact path="/workstyle/:providerId" component={WorkStyle} />
                <Route exact path="/persona/:code" component={ExternalPersona} />
                <Route exact path="/terms" component={TermsPage} />
                <Route exact path="/privacy-policy" component={PolicyPage} />
                <Route path="/schedule/:provider_id/:id" component={ScheduleQuestion}></Route>
                <Route path="/questionnaire/:provider_id" component={IdentifyQuestionnaire}></Route>
                <Route path="/pulse/:provider_id/:id" component={PulseQuestion}></Route>
                {/* ... (End) */}
                {/* <Route path="/organization" component={OrganizationPage}></Route> */}
                <Route path="/accept">
                  <AcceptPageAsync />
                </Route>

                <Route path="/pricing">
                  <PricingPageAsync />
                </Route>

                <Route path="/pay-success">
                  <CheckoutSuccessPageAsync />
                </Route>

                <Route path="/unsubscribe">
                  <UnsubscribePageAsync />
                </Route>

                {afterCheckLogin && <Route path="*" render={() => <NotFound />} />}
              </Switch>
            </Suspense>
          </Layout>
        </Route>
      ) : isLoggedIn ? (
        <Route
          exact
          path={[
            "/",
            // "/login",
            // "/signup",
            "/questionnaire",
            "/workstyle-time",
            "/workstyle/:providerId",
            "/persona/:code",
            "/terms",
            "/privacy-policy",
            "/schedule/:provider_id/:id",
            "/pulse/:provider_id/:id",
            "/questionnaire/:provider_id",
            "/p/:providerId",
            "/profile/:providerId",
            "/teams",
            "/team/:id/overview",
            "/people",
            "/settings",
            "/dashboard",
            "/my-workstyle",
            "/workstyle-list",
            "/workstyle-detail/:code",
            "/coworkers",
            "/coworker/profile/:providerId",
            "/lenses",
            "/questionnaire-answer",
            "/import/learn-more",
            "/observer",
            "/metting",
            "/discover",
            "/feeds",
            "/pulses",
            "/accept",
            "/groq",
            "/pricing",
            "/pay-success",
            "/trial",
            "/unsubscribe",
          ]}>
          <PrivateLayout currentUser={currentUser} isLoggedIn={isLoggedIn}>
            <Suspense fallback={<SupenseLoadingLine />}>
              <Switch>
                {currentToken && (
                  <>
                    <Route exact path="/" component={HomePage} />
                    {/* <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/signup" component={Register} /> */}
                    <Route exact path="/questionnaire" component={PublicQuestionnaire} />
                    <Route exact path="/workstyle/:providerId" component={WorkStyle} />
                    <Route exact path="/persona/:code" component={ExternalPersona} />
                    <Route exact path="/terms" component={TermsPage} />
                    <Route exact path="/privacy-policy" component={PolicyPage} />
                    <Route path="/schedule/:provider_id/:id" component={ScheduleQuestion}></Route>
                    <Route path="/questionnaire/:provider_id" component={IdentifyQuestionnaire}></Route>
                    <Route path="/pulse/:provider_id/:id" component={PulseQuestion}></Route>
                    <Route path="/p/:providerId" exact component={AsyncProfilePage} />
                    <Route path="/profile/:providerId" exact component={AsyncProfilePageOther} />
                    <Route path="/teams" component={TeamsPage}></Route>
                    {hasPermissionAdmin && <Route path="/team/:id/overview" component={TeamOverview}></Route>}
                    {hasPermissionAdmin && (
                      <Route exact path="/settings">
                        <SettingPage currentUser={currentUser} />
                      </Route>
                    )}
                    <Route path="/people">
                      <PeoplePage currentUser={currentUser} />
                    </Route>
                    {hasPermissionAdminAndManager && Helper.isFeature("dashboard", companyFeatures) && (
                      <Route path="/dashboard">
                        <DashboardPage currentUser={currentUser} />
                      </Route>
                    )}
                    <Route path="/my-workstyle" component={MyWorkstyle} />
                    <Route path="/workstyle-list">
                      <WorkstyleList currentUser={currentUser} />
                    </Route>
                    <Route path="/workstyle-detail/:code">
                      <WorkstyleDetailPage currentUser={currentUser} />
                    </Route>
                    <Route path="/coworkers">
                      <CoWorkerPage currentUser={currentUser} />
                    </Route>
                    <Route path="/coworker/profile/:providerId">
                      <CoworkerProfile currentUser={currentUser} />
                    </Route>
                    {Helper.isFeature("lenses", companyFeatures) && (
                      <Route path="/lenses">
                        <LensesPage currentUser={currentUser} />
                      </Route>
                    )}
                    <Route path="/questionnaire-answer">
                      <QuestionnaireAnswer currentUser={currentUser} />
                    </Route>
                    <Route path="/workstyle-time">
                      <WorkstyleTime currentUser={currentUser} />
                    </Route>
                    <Route path="/import/learn-more">
                      <LearnMore />
                    </Route>
                    {isObserver && (
                      <Route path="/observer">
                        <Observers currentUser={currentUser} />
                      </Route>
                    )}
                    <Route path="/metting">
                      <MettingPage />
                    </Route>
                    <Route path="/discover">
                      <Discover />
                    </Route>
                    <Route path="/feeds">
                      <Feed />
                    </Route>
                    <Route path="/pulses">
                      <Pulses />
                    </Route>
                    <Route path="/accept">
                      <AcceptPageAsync />
                    </Route>

                    {hasPermissionAdminAndManager && Helper.isFeature("harmonics_AI", companyFeatures) && (
                      <Route path="/groq">
                        <GropPageAsync />
                      </Route>
                    )}

                    <Route path="/pricing">
                      <PricingPageAsync />
                    </Route>
                    <Route path="/pay-success">
                      <CheckoutSuccessPageAsync />
                    </Route>
                    <Route path="/unsubscribe">
                      <UnsubscribePageAsync />
                    </Route>
                  </>
                )}

                {afterCheckLogin && <Route path="*" render={() => <NotFound />} />}
              </Switch>
              {showWorkstyleNotification && (
                <WorkstyleNotification
                  onChange={() => {
                    authService.setShowNotificationWorkstyle(SHOW_WORKSTYLE_NOTIFICATION.SHOWRED);
                    setShowWorkstyleNotification(false);
                  }}
                />
              )}
            </Suspense>
          </PrivateLayout>
        </Route>
      ) : (
        <></>
      )}
    </BrowserRouter>
  );
};

export default Router;
