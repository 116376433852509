import { atom } from "recoil";
import { Profile } from "../definitions";
export const userProfile = atom<Profile | undefined>({
  key: "userProfile",
  default: undefined,
});

export const token  = atom<string | undefined>({
  key: "token",
  default: undefined,
});

export const affinityTeamId = atom<string | undefined>({
  key: "affinityTeamId",
  default: undefined,
});