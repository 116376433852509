import Cookies from "js-cookie";
import React from "react";
import { QUESTION_ROUTE } from "../utils/constant";
import _ from "lodash";
import PlainAdminButton from "../common/PlainAdmin-Button";

type Props = {
  icon: string;
  onChangeShowMenu: Function;
};

const SidebarLogin = (props: Props) => {
  const companyName = Cookies.get("companyName");
  const questionHeaderRouter = QUESTION_ROUTE;
  const array_path = _.split(window.location.pathname, "/");

  const isQuestionRoute = _.find(questionHeaderRouter, function (o) {
    return o === array_path[1] || o === window.location.pathname;
  });
  return (
    <header className="header login">
      <div className="container">
        <div className="d-flex justify-content-space-between">
          <div className="flex">
            <div className="header-left d-flex align-items-center">
              <a href="https://www.heyharmonic.com/">
                <img src={process.env.PUBLIC_URL + "/harmonic-logo-full-colour.png"} width="175" alt="logo" />
              </a>
            </div>
          </div>
          {isQuestionRoute && companyName && <div className="flex text-center company-name">{companyName}</div>}
          <div className="d-none d-xs-block">
            <div className="d-flex align-items-center">
              <div className="menu-toggle-btn">
                <PlainAdminButton type={"primary-btn btn-hover pt-10 pb-10 pl-15 pr-15"} onClick={() => props.onChangeShowMenu()}>
                  <i className={`lni ${props.icon}`}></i>
                  Menu
                </PlainAdminButton>
              </div>
            </div>
          </div>
          <div className="flex text-right d-xs-none">
            <a href="/signup" className="main-btn rounded-full btn-hover mt-3">
              Register
            </a>
            <a href="/login" className="main-btn primary-btn rounded-full btn-hover mt-3">
              Login
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
export default SidebarLogin;
