import _ from "lodash";
import React, { forwardRef } from "react";

type Props = {
  label?: string;
  placeholder?: string;
  onChange?: Function;
  onBlur?: Function;
  onKeyDown?: Function;
  error?: string;
  type: string;
  defaultValue?: string;
  children?: any;
  readonly?: boolean;
  radius?: string;
  className?: string;
};

const PlainAdminFormInput = forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  return (
    <div className={`input-style-1 ${props.error ? "was-invalied" : ""} ${props.className ? props.className : ""}`}>
      {props.label && <label> {props.label}</label>}
      <input
        ref={ref}
        style={{ borderRadius: props.radius ? props.radius : 4 }}
        className="bg-transparent"
        type={props.type}
        readOnly={props.readonly}
        placeholder={props.placeholder || ""}
        onChange={(e: any) => (props.onChange ? props.onChange(e.target.value) : _.noop())}
        onBlur={(e: any) => (props.onBlur ? props.onBlur(e.target.value) : _.noop())}
        onKeyDown={(e: any) => {
          props.onKeyDown ? props.onKeyDown(e) : _.noop();
        }}
        defaultValue={props.defaultValue || ""}
      />
      {props.error && <div className="invalid-feedback d-block">{props.error}</div>}
      {props.children ? props.children : null}
    </div>
  );
});
export default PlainAdminFormInput;
