import React, { useEffect, useState } from "react";
import Body from "../layout/body";
import Header from "../layout/header";
import { RouteComponentProps, withRouter } from "react-router";
import { Profile } from "../definitions";
import Sidebar from "./sidebar";
import SidebarLogin from "./sidebar-login";
import AuthService from "./../auth.service";
import _ from "lodash";
import { PUBLIC_ROUTE } from "../utils/constant";
import HeaderAuth from "./header-auth";
import { useRecoilState } from "recoil";
import { pulseStarted } from "../recoil/pulseState";
import { userProfile } from "../recoil/userProfile";

export type LayoutProps = RouteComponentProps & {
  currentUser?: Profile;
  isLoggedIn?: boolean;
  children?: any;
};
const Layout = (props: LayoutProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showMenuLeft, setShowMenuLeft] = useState(true);
  const [showMenuAuth, setShowMenuAuth] = useState(false);
  const [showTopMenu, setShowTopMenu] = useState(false);
  const public_routes = PUBLIC_ROUTE;
  const array_path = _.split(window.location.pathname, "/");
  const [currentPulseStarted, setCurrentPulseStarted] = useRecoilState(pulseStarted);
  const [currentUserProfile, setCurrentUserProfile] = useRecoilState(userProfile);

  const isPublicRoute = _.find(public_routes, function (o) {
    return o === array_path[1] || o === window.location.pathname;
  })
    ? true
    : false;
  useEffect(() => {
    getAuthenticated();
    if (window.location.pathname === "/") {
      setShowMenuLeft(false);
    }
  }, []);
  useEffect(() => {
    // if(props.currentUser && props.currentUser.company && props.currentUser.company.affinity_started){
    //   setCurrentPulseStarted(props.currentUser.company.affinity_started);
    // }
    if(props.currentUser) {
      setCurrentUserProfile(props.currentUser);
    }
  },[props.currentUser])
  const getAuthenticated = async () => {
    let isAuthenticated = await AuthService.isAuthenticated();
    setIsAuthenticated(isAuthenticated);
    if (isAuthenticated) {
      setShowMenuLeft(false);
      setShowTopMenu(false);
    } else {
      setShowTopMenu(true);
    }
  };
  return (
    <React.Fragment>
      {!isAuthenticated && (
        <aside className={`sidebar-nav-wrapper d-none d-xs-block ${!showMenuAuth ? "" : "active"}`}>
          {/* <HeaderAuth {...props}></HeaderAuth> */}
        </aside>
      )}
      <main
        className={`main-wrapper ${!showMenuLeft ? "" : "active"} ${window.location.pathname === "/" ? "ml-0" : ""}`}>
        {isAuthenticated ? (
          <Sidebar
            currentUser={props.currentUser}
            icon={!showMenuLeft ? "lni-menu" : "lni-chevron-left"}
            onChangeShowMenu={(e: any) => {
              setShowMenuLeft(!showMenuLeft);
            }}
          />
        ) : (
          <SidebarLogin
            icon={!showMenuAuth ? "lni-menu" : "lni-chevron-left"}
            onChangeShowMenu={() => {
              setShowMenuAuth(!showMenuAuth);
            }}
          />
        )}
        {/* {(showTopMenu && isPublicRoute && !props.currentUser) && <SidebarLogin />} */}
        <section className="section">
          <div className="container-fluid">
            <Body {...props}>
              {React.Children.map(props.children, (child) => {
                return React.cloneElement(child as any, { ...props });
              })}
            </Body>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default withRouter(Layout);
